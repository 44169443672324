import React from 'react';
import Layout from '../components/layout';

const Sustentabilidad = () => (
  <Layout>
    <section className="max-w-3xl mx-auto px-4 py-16">
      <h1 className="text-4xl font-extrabold text-teal-700 mb-4">
        Sustentabilidad
      </h1>
      {/* 
      <h2 className="text-xl font-extrabold text-blue-900 mb-4">
        Certificación Forestal FSC
      </h2>

      <p className="mt-6 text-base md:text-xl">
        El F.S.C. (Forest Stewardship Council o Consejo de Administración
        Forestal) es una organización forestal internacional no gubernamental,
        sin fines de lucro e independiente. Su misión es promover el manejo
        sustentable del medio ambiente en bosques y obtener beneficios
        socioeconómicos para la empresa y sus integrantes. Los beneficios para
        el cliente son: origen del producto, trazabilidad, cuidado del medio
        ambiente, respeto de los derechos del trabajador; y para la empresa:
        apertura de mercados, mejoramiento de precios en contratos, mejoramiento
        en la organización de la empresa.
      </p>

      <p className="mt-6 text-base md:text-xl">
        La empresa Induba S.R.L. tiene la certificación aprobada por el F.S.C.
        desde el año 2019 y se compromete a continuar con el cumplimento de
        estas normas, bajo un manejo forestal ecológicamente y socialmente
        sustentable.
      </p> */}
    </section>
  </Layout>
);

export default Sustentabilidad;
